<template>
  <div class="receives animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>مدیریت دریافت‌ها</h3>
          </v-col>
          <v-col>
            <v-btn
              class="primary-btn"
              style="float: left"
              @click="openAddDialog()"
            >
              <v-icon left>add</v-icon>
              <h6 class="mt-1">ثبت دریافت جدید</h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="timeFrom">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="timeFrom"
                    label=" ساعت از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="timeFrom"
                  element="timeFrom"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="timeTo">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="timeTo"
                    label=" ساعت تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="timeTo"
                  element="timeTo"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="submit-btn primary-btn"
                  style="float: right"
                  @click="getReceivesList()"
                  :disabled="Busy"
                  >اعمال</v-btn
                >
              </v-col>
            </v-row>
            <hr />

            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mt-2 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده دریافتی ثبت نشده‌است"
              empty-filtered-text="در بازه زمانی انتخاب شده دریافتی ثبت نشده‌است"
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              @row-clicked="onRowSelected"
              tbody-tr-class="trClass"
              :filter-function="myFilterFn"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    data.item.role == "patient"
                      ? data.item.patientName
                      : data.item.role == "employee"
                      ? data.item.userName
                      : "-"
                  }}
                </div>
              </template>
              <template v-slot:cell(role)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    data.item.role == "patient"
                      ? "بیمار"
                      : data.item.role == "employee"
                      ? "کارمند"
                      : "آزاد"
                  }}
                </div>
              </template>
              <template v-slot:cell(amount)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ Number(data.value).toLocaleString() }}
                </div>
              </template>
              <template v-slot:cell(transactionFee)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    data.item.transferType == "کارت" ||
                    data.item.transferType == "چک"
                      ? Number(data.value).toLocaleString()
                      : "-"
                  }}
                </div>
              </template>
              <template v-slot:cell(description)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-tooltip
                    right
                    max-width="600"
                    v-if="deviceType != 'mobile'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{
                          data.value.length > 20
                            ? data.value.slice(0, 20) + "..."
                            : data.value
                        }}
                      </span>
                    </template>
                    <p class="text-right p-2 text-justify">{{ data.value }}</p>
                  </v-tooltip>
                  <p v-else>
                    {{
                      data.value.length > 20
                        ? data.value.slice(0, 20) + "..."
                        : data.value
                    }}
                  </p>
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    v-if="!data.item.isRefunded"
                    class="primary-btn pa-2"
                    @click="openModal(data.item)"
                    style="width: 100%; margin-top: 2px"
                    ><v-icon>mdi-cash-multiple</v-icon>
                    استرداد
                  </v-btn>
                  <span v-else>-</span>
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="addReceiveItemDialog" width="600">
      <v-card
        class="pa-3 modal-card"
        style="overflow-y: auto; overflow-x: hidden"
      >
        <v-card-title class="mb-2">
          <h4 class="text-right">ثبت دریافت جدید برای کارمند</h4>
          <v-spacer></v-spacer>
          <v-icon color="red" class="mb-2" @click="addReceiveItemDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="pb-0 mb-0">
          <v-select
            v-model="receiveDetail.userId"
            :items="employees"
            item-text="text"
            item-value="id"
            label="کارمند"
            v-if="!employeeBusy"
            outlined
            dense
          ></v-select>
          <vuetify-money
            v-model="receiveDetail.amount"
            label="مبلغ (ریال)"
            :valueWhenIsEmpty="whenIsEmpty"
            :options="vMoneyOptions"
            type="number"
            outlined
            dense
          ></vuetify-money>
          <v-row class="time-row">
            <v-col cols="12">
              <span id="newDate">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="receiveDetail.newDate"
                  label=" تاریخ "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="receiveDetail.newDate"
                element="newDate"
                color="#00a7b7"
              /> </v-col
            ><v-col cols="12">
              <span id="newTime">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="mdi-clock-outline"
                  v-model="receiveDetail.newTime"
                  label=" ساعت"
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="receiveDetail.newTime"
                element="newTime"
                type="time"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-select
                v-model="receiveDetail.transferType"
                :items="paymentTypes"
                item-text="text"
                item-value="value"
                label="نحوه دریافت"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            v-if="
              receiveDetail.transferType == 'card' ||
              receiveDetail.transferType == 'cheque'
            "
          >
            <v-col class="pb-0">
              <div v-if="receiveDetail.transferType == 'card'">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="receiveDetail.cardNumberSender"
                  label="شماره کارت فرستنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="receiveDetail.cardNumberReciever"
                  label="شماره کارت گیرنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
              </div>
              <div v-if="receiveDetail.transferType == 'cheque'">
                <v-text-field
                  outlined
                  dense
                  v-model="receiveDetail.chequeBankName"
                  label="نام بانک "
                >
                </v-text-field>
                <vuetify-money
                  v-model="receiveDetail.chequeAmount"
                  label="مبلغ چک (ریال)"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  type="number"
                  outlined
                  dense
                ></vuetify-money>
                <v-text-field
                  outlined
                  dense
                  v-model="receiveDetail.chequeNumber"
                  label="شماره چک"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-model="receiveDetail.chequeSayyad"
                  label="شناسه صیاد"
                >
                </v-text-field>
                <span id="newCheque">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="receiveDetail.chequeNewDate"
                    label=" تاریخ چک"
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="receiveDetail.chequeNewDate"
                  element="newCheque"
                  color="#00a7b7"
                />
                <v-row class="time-row mb-4">
                  <v-col>
                    <v-select
                      v-model="receiveDetail.chequeStatus"
                      :items="chequeStatuses"
                      item-text="text"
                      item-value="value"
                      label="وضعیت چک"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-textarea
                  outlined
                  dense
                  type="text"
                  v-model="receiveDetail.chequeDescription"
                  label=" توضیحات چک"
                >
                </v-textarea>
              </div>
            </v-col>
          </v-row>
          <div
            :class="
              receiveDetail.transferType == 'cash' ||
              receiveDetail.transferType == 'pos' ||
              !receiveDetail.transferType
                ? 'mt-5'
                : 'mt-2'
            "
          >
            <vuetify-money
              v-model="receiveDetail.transactionFee"
              label="کارمزد انتقال وجه (ریال)"
              :valueWhenIsEmpty="whenIsEmpty"
              :options="vMoneyOptions"
              type="number"
              outlined
              dense
              v-if="
                receiveDetail.transferType == 'card' ||
                receiveDetail.transferType == 'cheque'
              "
            ></vuetify-money>
            <p class="text-right mb-2">
              * شما میتوانید بیش از یک فایل انتخاب کنید.
            </p>
            <v-file-input
              @change="myUpload(receiveDetail.file)"
              v-model="receiveDetail.file"
              chips
              outlined
              dense
              label="بارگذاری فایل"
              prepend-inner-icon="upload_file"
              prepend-icon=""
              class="file-input"
            ></v-file-input>
            <v-progress-linear
              rounded
              v-if="myFile.showProgress"
              height="10"
              :value="myFile.uploadPercentage"
              class="mb-4 mt-0"
            >
            </v-progress-linear>
          </div>
          <v-row>
            <div
              v-if="myFile.isUploaded"
              color="green"
              class="my-2 px-3"
              style="font-size: 16px"
            >
              فایل با موفقیت بارگذاری شد.
            </div>
          </v-row>
          <div v-if="uploadedFiles.length" class="files-box d-flex flex-column">
            <div class="files-title">
              فایل‌های آپلود شده ({{ uploadedFiles.length }})
            </div>
            <span
              v-for="(item, index) in uploadedFiles"
              :key="index"
              class="mb-2 pt-2"
            >
              <span @click="deleteFile(index)" class="pointer">
                <v-icon class="red--text text--lighten-1 pe-2"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <a :href="item.url" target="blank">{{ showName[index] }}</a>
            </span>
          </div>
          <v-textarea
            v-model="receiveDetail.description"
            label="توضیحات"
            outlined
            dense
            class="mt-5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn"
            outlined
            :disabled="computedAddBtn"
            @click="addToReceiveList()"
          >
            ثبت
          </v-btn>
          <v-btn
            class="red-btn"
            outlined
            @click="
              addReceiveItemDialog = false;
              uploadedFiles = [];
              myFile.isUploaded = false;
              myFile.showProgress = false;
            "
          >
            لغو
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="receiveItemDialog" width="600">
      <v-card
        class="modal-card pa-3"
        v-if="!edited"
        style="max-height: 600px !important; overflow-y: auto"
      >
        <v-card-title class="mb-2">
          <h4>مشاهده جزییات دریافت</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2"
            @click="
              receiveItemDialog = false;
              edited = false;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col v-if="selected.userName">
              <span class="h2 primary--text">نام کارمند:</span>
              <span class="h2"> {{ selected.userName }}</span>
            </v-col>
            <v-col v-if="selected.patientName">
              <span class="h2 primary--text">نام بیمار:</span>
              <span class="h2"> {{ selected.patientName }}</span>
            </v-col>
            <v-col>
              <span class="h2 primary--text">مبلغ :</span>
              <span class="h2">
                {{ Number(selected.amount).toLocaleString() }} ریال</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">تاریخ دریافت:</span>
              <span class="h2"> {{ selected.date }}</span>
            </v-col>
            <v-col>
              <span class="h2 primary--text">ساعت دریافت :</span>
              <span class="h2"> {{ selected.time }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-2">
              <span class="h2 primary--text">نحوه دریافت :</span>
              <span class="h2"> {{ selected.transferType }}</span>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selected.transferType == 'کارت' || selected.transferType == 'چک'
            "
          >
            <v-col>
              <div
                v-if="selected.transferType == 'کارت'"
                class="cardInfo-box ps-2 py-2 d-flex flex-column"
              >
                <div>
                  <span class="h3 primary--text">شماره کارت فرستنده :</span>
                  <span class="h3 float-end" dir="ltr">
                    {{ cardNumberSpacer(selected.cardNumberSender) }}</span
                  >
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شماره کارت گیرنده :</span>
                  <span class="h3 float-end" dir="ltr">
                    {{ cardNumberSpacer(selected.cardNumberReciever) }}</span
                  >
                </div>
              </div>
              <div
                v-if="selected.transferType == 'چک'"
                class="cardInfo-box ps-2 py-2 d-flex flex-column"
              >
                <div>
                  <span class="h3 primary--text">نام بانک :</span>
                  <span class="h3"> {{ selected.chequeBankName }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">مبلغ چک :</span>
                  <span class="h3">
                    {{ Number(selected.chequeAmount).toLocaleString() }}
                    ریال</span
                  >
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شماره چک :</span>
                  <span class="h3"> {{ selected.chequeNumber }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">شناسه صیاد :</span>
                  <span class="h3">
                    {{
                      selected.chequeSayyad ? selected.chequeSayyad : "--"
                    }}</span
                  >
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">تاریخ چک :</span>
                  <span class="h3"> {{ selected.chequeDueDate }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">وضعیت چک :</span>
                  <span class="h3"> {{ selected.chequeStatus }}</span>
                </div>
                <div class="mt-5">
                  <span class="h3 primary--text">توضیحات چک :</span>
                  <div
                    class="h3"
                    style="line-height: 22px; text-align: justify"
                  >
                    {{
                      selected.chequeDescription
                        ? selected.chequeDescription
                        : "توضیحی ثبت نشده است."
                    }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selected.transferType == 'کارت' || selected.transferType == 'چک'
            "
          >
            <v-col>
              <span class="h2 primary--text">کارمزد انتقال وجه:</span>
              <span class="h2">
                {{ Number(selected.transactionFee).toLocaleString() }} ریال
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="selected.files">
              <span class="h2 primary--text">
                فایل‌ها ({{ selected.files.length }}):</span
              >
              <!-- قسمت مربوط به فایل (لینک فایل گرفته شود) -->
              <span v-if="!selected.files.length" style="font-size: 15px">
                --
              </span>
              <div class="d-flex flex-column mt-1 ps-5">
                <ul>
                  <li
                    v-for="(item, index) in selected.files"
                    :key="index"
                    class="pt-5"
                    style="font-size: 15px"
                  >
                    <a :href="item.url" target="blank">{{
                      item.name.length > 50
                        ? item.name.slice(0, 50) + "..."
                        : item.name
                    }}</a>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="selected.title">
            <v-col>
              <span class="h2 primary--text">دریافت بابت :</span>

              <div
                class="h3 mt-1"
                style="line-height: 22px; text-align: justify"
              >
                {{ selected.title }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">توضیحات :</span>

              <div
                class="h3 mt-1"
                style="line-height: 22px; text-align: justify"
              >
                {{ selected.description }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn @click="openEditDialog()" class="secondary-btn mb-4 me-5 pa-5">
          <v-icon left>edit</v-icon>
          ویرایش</v-btn
        >
      </v-card>
      <v-card
        class="modal-card"
        v-else
        style="
          max-height: 600px !important;
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <v-card-title class="mb-2">
          <h4>ویرایش دریافت</h4>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 time-row">
            <v-col>
              <div
                class="disabled-fields grey lighten-3"
                v-if="selectedEdit.userName"
              >
                <span class="h3"> نام کارمند: {{ selectedEdit.userName }}</span>
              </div>
              <div
                class="disabled-fields grey lighten-3"
                v-if="selectedEdit.patientName"
              >
                <span class="h3">
                  نام بیمار: {{ selectedEdit.patientName }}</span
                >
              </div>
            </v-col>
            <v-col>
              <vuetify-money
                v-model="selectedEdit.amount"
                label="مبلغ (ریال)"
                :valueWhenIsEmpty="whenIsEmpty"
                :options="vMoneyOptions"
                type="number"
                outlined
                dense
              ></vuetify-money>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <span id="newDate2">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="selectedEdit.date"
                  label=" تاریخ "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.date"
                element="newDate2"
                color="#00a7b7"
              /> </v-col
            ><v-col>
              <span id="newTime2">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="mdi-clock-outline"
                  v-model="selectedEdit.time"
                  label=" ساعت "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.time"
                element="newTime2"
                type="time"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col
              ><v-select
                v-model="selectedEdit.transferType"
                :items="paymentTypes"
                item-text="text"
                item-value="value"
                label="نحوه دریافت"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-row
            class="time-row"
            v-if="
              selectedEdit.transferType == 'card' ||
              selectedEdit.transferType == 'cheque'
            "
          >
            <v-col>
              <div v-if="selectedEdit.transferType == 'card'">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  class="mb-4"
                  v-model="selectedEdit.cardNumberSender"
                  label="شماره کارت فرستنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model="selectedEdit.cardNumberReciever"
                  label="شماره کارت گیرنده "
                  @wheel="$event.target.blur()"
                >
                </v-text-field>
              </div>

              <div v-if="selectedEdit.transferType == 'cheque'">
                <v-text-field
                  outlined
                  dense
                  v-model="selectedEdit.chequeBankName"
                  label="نام بانک "
                  class="mb-4"
                >
                </v-text-field>
                <vuetify-money
                  v-model="selectedEdit.chequeAmount"
                  label="مبلغ چک (ریال)"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  type="number"
                  outlined
                  dense
                  class="mb-4"
                ></vuetify-money>
                <v-text-field
                  outlined
                  dense
                  v-model="selectedEdit.chequeNumber"
                  label="شماره چک"
                  class="mb-4"
                >
                </v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-model="selectedEdit.chequeSayyad"
                  label="شناسه صیاد"
                  class="mb-4"
                >
                </v-text-field>
                <span id="newChequeDate">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="selectedEdit.chequeDueDate"
                    label=" تاریخ چک"
                    :editable="true"
                    class="date-input mb-4"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="selectedEdit.chequeDueDate"
                  element="newChequeDate"
                  color="#00a7b7"
                />
                <v-row class="time-row mb-4">
                  <v-col
                    ><v-select
                      v-model="selectedEdit.chequeStatus"
                      :items="chequeStatuses"
                      item-text="text"
                      item-value="value"
                      label="وضعیت چک"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-textarea
                  outlined
                  dense
                  type="text"
                  v-model="selectedEdit.chequeDescription"
                  label=" توضیحات چک"
                >
                </v-textarea>
              </div>
            </v-col>
          </v-row>
          <v-row
            class="time-row"
            v-if="
              selectedEdit.transferType == 'cheque' ||
              selectedEdit.transferType == 'card'
            "
          >
            <v-col>
              <vuetify-money
                v-model="selectedEdit.transactionFee"
                label="کارمزد انتقال وجه (ریال)"
                :valueWhenIsEmpty="whenIsEmpty"
                :options="vMoneyOptions"
                type="number"
                outlined
                dense
              ></vuetify-money>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <p class="text-right mb-2 h6">
                * شما میتوانید بیش از یک فایل انتخاب کنید.
              </p>
              <v-file-input
                @change="editedUpload(file)"
                v-model="file"
                chips
                outlined
                dense
                label=" بارگذاری فایل"
                prepend-inner-icon="upload_file"
                prepend-icon=""
                class="file-input mb-6"
              ></v-file-input>
              <v-progress-linear
                rounded
                v-if="myFile.showProgress"
                height="10"
                :value="myFile.uploadPercentage"
                class="mb-4 mt-0"
              >
              </v-progress-linear>
              <v-row>
                <div
                  v-if="myFile.isUploaded"
                  color="green"
                  class="mt-3 mb-1 pa-3"
                  style="font-size: 16px"
                >
                  فایل با موفقیت بارگذاری شد.
                </div>
              </v-row>
            </v-col>
          </v-row>
          <div
            v-if="selectedEdit.files.length"
            class="files-box d-flex flex-column mb-6"
          >
            <div class="files-title">
              فایل‌های آپلود شده ({{ selectedEdit.files.length }})
            </div>
            <span
              v-for="(item, index) in selectedEdit.files"
              :key="index"
              class="mb-2 pt-2"
            >
              <span @click="deleteFile2(index)" class="pointer">
                <v-icon class="red--text text--lighten-1 pe-2"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <a :href="item.url" target="blank">{{ item.name }}</a>
            </span>
          </div>
          <v-row class="time-row">
            <v-col>
              <v-textarea
                v-model="selectedEdit.description"
                label="توضیحات"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <v-btn
            :disabled="computedEditBtn"
            @click="editReceiveItem()"
            class="primary-btn mt-4 mx-2"
            >ثبت</v-btn
          >
          <v-btn
            @click="
              edited = false;
              uploadedFiles = [];
              myFile.isUploaded = false;
              myFile.showProgress = false;
            "
            class="red-btn mt-4"
            >لغو</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="refundDialog" width="600px">
      <v-card>
        <v-card-title>
          <h4 class="primary--text">بازپرداخت</h4>
        </v-card-title>
        <v-card-text class="pb-0">
          <h6 class="text-right">
            <span>
              شما در حال بازپرداخت مبلغ:
              {{ Number(selectedRefund.amount).toLocaleString() }} ریال
              هستید.</span
            >
          </h6>
          <v-row class="time-row mt-3">
            <v-col
              ><v-select
                v-model="selectedRefund.transferType"
                :items="paymentTypes"
                item-text="text"
                item-value="value"
                label="نحوه پرداخت"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            class="time-row"
            v-if="
              selectedRefund.transferType == 'card' ||
              selectedRefund.transferType == 'cheque'
            "
          >
            <v-col>
              <div v-if="selectedRefund.transferType == 'card'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h5>اطلاعات کارت‌ها</h5>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    class="mb-4"
                    v-model="selectedRefund.cardNumberSender"
                    label="شماره کارت فرستنده*"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="selectedRefund.cardNumberReciever"
                    label="شماره کارت گیرنده*"
                  >
                  </v-text-field>
                  <vuetify-money
                    v-model="selectedRefund.transactionFee"
                    label="کارمزد انتقال وجه (ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    class="mt-5"
                  ></vuetify-money>
                </div>
              </div>

              <div v-if="selectedRefund.transferType == 'cheque'">
                <div
                  style="border: 1px grey solid; border-radius: 8px"
                  class="px-3 py-2"
                >
                  <h5>اطلاعات چک</h5>
                  <vuetify-money
                    v-model="selectedRefund.chequeAmount"
                    label="مبلغ چک (ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    class="mb-4"
                  ></vuetify-money>
                  <v-text-field
                    outlined
                    dense
                    v-model="selectedRefund.chequeBankName"
                    label="نام بانک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="selectedRefund.chequeNumber"
                    label="شماره چک*"
                    class="mb-4"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="selectedRefund.chequeSayyad"
                    label="شناسه صیاد"
                    class="mb-4"
                  >
                  </v-text-field>
                  <span id="newChequeDate">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="selectedRefund.chequeDueDate"
                      label=" تاریخ چک*"
                      :editable="true"
                      class="date-input mb-4"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="selectedRefund.chequeDueDate"
                    element="newChequeDate"
                    color="#00a7b7"
                  />
                  <v-select
                    v-model="selectedRefund.chequeStatus"
                    :items="chequeStatuses"
                    item-text="text"
                    item-value="value"
                    label="وضعیت چک"
                    outlined
                    dense
                  ></v-select>
                  <v-textarea
                    label="توضیحات چک"
                    outlined
                    dense
                    class="mt-5"
                    v-model="selectedRefund.chequeDescription"
                  >
                  </v-textarea>
                  <vuetify-money
                    v-model="selectedRefund.transactionFee"
                    label="کارمزد انتقال وجه (ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    class="mt-5"
                  ></vuetify-money>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-file-input
                @change="refundUpload(file)"
                v-model="file"
                chips
                outlined
                dense
                label=" بارگذاری فایل"
                prepend-inner-icon="upload_file"
                prepend-icon=""
                class="file-input mb-6"
              ></v-file-input>
              <v-progress-linear
                rounded
                v-if="myFile2.showProgress"
                height="10"
                :value="myFile2.uploadPercentage"
                class="mb-4 mt-0"
              >
              </v-progress-linear>
              <v-row>
                <div
                  v-if="myFile2.isUploaded"
                  color="green"
                  class="my-1 pa-3"
                  style="font-size: 16px"
                >
                  فایل با موفقیت بارگذاری شد.
                </div>
              </v-row>
            </v-col>
          </v-row>
          <div
            v-if="selectedRefund.files && selectedRefund.files.length > 0"
            class="files-box d-flex flex-column mb-6"
          >
            <div class="files-title">
              فایل‌های آپلود شده ({{ selectedRefund.files.length }})
            </div>
            <span
              v-for="(item, index) in selectedRefund.files"
              :key="index"
              class="mb-2 pt-2"
            >
              <span @click="deleteRefundFile(index)" class="pointer">
                <v-icon class="red--text text--lighten-1 pe-2"
                  >mdi-close-circle-outline</v-icon
                >
              </span>
              <a :href="item.url" target="blank">{{ item.name }}</a>
            </span>
          </div>
          <v-row>
            <v-col
              ><v-textarea
                outlined
                dense
                type="text"
                v-model="refundDescription"
                label="توضیحات"
              >
              </v-textarea
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn"
            outlined
            :loading="isRefunding"
            :disabled="!refundDisabled()"
            @click="submitRefund()"
          >
            ثبت
          </v-btn>
          <v-btn
            class="red-btn"
            outlined
            :disabled="isRefunding"
            @click="
              refundDialog = false;
              uploadedFiles = [];
              myFile2.isUploaded = false;
              myFile2.showProgress = false;
            "
          >
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      deviceType: "",
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام" },
        { key: "role", label: "نقش" },
        { key: "amount", label: "مبلغ (ریال)" },
        { key: "date", label: "تاریخ دریافت" },
        { key: "time", label: "ساعت دریافت" },
        { key: "transferType", label: "نحوه دریافت" },
        { key: "transactionFee", label: "کارمزد انتقال وجه (ریال)" },
        { key: "fileIds", label: "تعداد فایل‌ها" },
        { key: "description", label: "توضیحات" },
        { key: "operation", label: "عملیات" },
      ],
      Items: [],
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      addReceiveItemDialog: false,
      types: [
        { value: "hourly", text: "ساعتی" },
        { value: "daily", text: "روزانه" },
      ],
      states: [
        { value: "eligible", text: "استحقاقی" },
        { value: "incentive", text: "تشویقی" },
        { value: "withoutPay", text: "بدون حقوق" },
        { value: "sick", text: "استعلاجی" },
      ],
      status: [
        { value: "registered", text: "ثبت اولیه" },
        { value: "approved", text: "تایید درخواست" },
        { value: "rejected", text: "رد درخواست" },
      ],
      showProgress: false,
      uploadButDisable: false,
      variant: "info",
      striped: true,
      max: 100,
      uploadPercentage: 0,
      isUploaded: false,
      file: [],
      reportFiles: [],
      request: {},
      vLoading: false,

      myFile: new Object({
        uploadPercentage: 0,
      }),
      myFile2: new Object({
        uploadPercentage: 0,
      }),
      fileBusy: false,
      fileFilter: "",
      receiveDetail: {
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeAmount: null,
        chequeNumber: "",
        chequeNewDate: "",
        chequeDescription: "",
        chequeSayyad: "",
        chequeStatus: "",
      },
      isRefunding: false,
      refundDialog: false,
      employees: [],
      employeeBusy: false,
      selected: {},
      edited: false,
      receiveItemDialog: false,
      selectedEdit: {},
      chequeStatuses: [
        { text: "پاس شده", value: "passed" },
        { text: "برگشت خورده", value: "returned" },
        { text: "ثبت شده", value: "submitted" },
      ],
      financialId: "",
      uploadedFiles: [],
      showName: [],
      showName2: [],
      refundDescription: "",
      refundId: "",
      fileIds: [],
      roles: [
        {
          key: "patient",
          value: "بیمار",
        },
        {
          key: "employee",
          value: "کارمند",
        },
      ],
      selectedRefund: {
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeAmount: "",
        chequeNumber: "",
        chequeNewDate: "",
        chequeStatus: "",
      },
    };
  },

  methods: {
    cardNumberSpacer(number) {
      number = number.match(new RegExp(".{1,4}", "g")).join("-");
      return number;
    },
    openAddDialog() {
      this.addReceiveItemDialog = true;
      this.receiveDetail = {
        cardNumberSender: "",
        cardNumberReciever: "",
        chequeBankName: "",
        chequeAmount: "",
        chequeNumber: "",
        chequeNewDate: "",
        chequeSayyad: "",
        newDate: moment(new Date()).format("jYYYY/jMM/jDD"),
        newTime: moment(new Date()).format("HH:mm"),
      };
      this.uploadedFiles = [];
      this.myFile.isUploaded = false;
      this.myFile.showProgress = false;
      this.getEmployeesList();
    },
    openEditDialog() {
      this.selected.transferType == "چک"
        ? (this.selectedEdit.chequeStatus = this.chequeStatuses.filter(
            (x) => x.text == this.selected.chequeStatus
          )[0].value)
        : "";

      this.edited = true;
    },
    getEmployeesList() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employees/list",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.employeeBusy = false;
          if (res.status == 200) {
            this.receiveDetail.userId = "";
            this.employees = res.data.map((x) => {
              return {
                id: x.id,
                text: x.name,
              };
            });
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    getReceivesList() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/documents/receives",
          {
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          this.vLoading = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.Busy = false;
        });
    },
    addToReceiveList() {
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/newReceive",
          {
            date: this.receiveDetail.newDate + this.receiveDetail.newTime,
            amount: this.receiveDetail.amount,
            description: this.receiveDetail.description,
            transactionFee:
              this.receiveDetail.transferType == "card" ||
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.transactionFee
                : "",
            transferType: this.receiveDetail.transferType,
            chequeAmount:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeAmount
                : "",
            chequeNumber:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeNumber
                : "",
            chequeBankName:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeBankName
                : "",
            chequeDueDate:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeNewDate
                : "",
            chequeSayyad:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeSayyad
                : "",
            chequeStatus:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeStatus
                : "",
            chequeDescription:
              this.receiveDetail.transferType == "cheque"
                ? this.receiveDetail.chequeDescription
                : "",
            cardNumberSender:
              this.receiveDetail.transferType == "card"
                ? this.receiveDetail.cardNumberSender
                : "",
            cardNumberReciever:
              this.receiveDetail.transferType == "card"
                ? this.receiveDetail.cardNumberReciever
                : "",
            userId: this.receiveDetail.userId,
            fileIds: this.fileIds,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.toast(res.data, "success");
            this.addReceiveItemDialog = false;
            this.receiveDetail = {
              cardNumberSender: "",
              cardNumberReciever: "",
              chequeBankName: "",
              chequeAmount: "",
              chequeNumber: "",
              chequeNewDate: "",
              chequeSayyad: "",
              chequeStatus: "",
            };
            this.showName = [];
            this.uploadedFiles = [];
            this.getReceivesList();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.addReceiveItemDialog = false;
            this.receiveDetail = {
              cardNumberSender: "",
              cardNumberReciever: "",
              chequeBankName: "",
              chequeAmount: "",
              chequeNumber: "",
              chequeNewDate: "",
              chequeSayyad: "",
              chequeStatus: "",
            };
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.addReceiveItemDialog = false;
          this.receiveDetail = {
            cardNumberSender: "",
            cardNumberReciever: "",
            chequeBankName: "",
            chequeAmount: "",
            chequeNumber: "",
            chequeNewDate: "",
            chequeSayyad: "",
            chequeStatus: "",
          };
        });
    },
    myUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.showName.push(file.name);
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/financial/document/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.uploadedFiles.push(d.data);
            this.fileIds.push(d.data.id);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },

    editedUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      this.showName.push(file.name);
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/financial/document/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.selectedEdit.files.push(d.data);
            let filesLength = this.selectedEdit.files.length;
            let namesLength = this.showName.length;
            this.selectedEdit.files[filesLength - 1].name =
              this.showName[namesLength - 1];
            this.selectedEdit.fileIds.push(d.data.id);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    deleteFile(index) {
      this.uploadedFiles.splice(index, 1);
      this.fileIds.splice(index, 1);
      this.showName.splice(index, 1);
    },
    deleteFile2(index) {
      this.selectedEdit.files.splice(index, 1);
      this.selectedEdit.fileIds.splice(index, 1);
      this.showName.splice(index, 1);
    },
    deleteRefundFile(index) {
      this.selectedRefund.files.splice(index, 1);
      this.selectedRefund.fileIds.splice(index, 1);
      this.showName2.splice(index, 1);
    },
    update() {
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/show",
          {
            financialId: this.financialId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.selected = res.data;
            this.selectedEdit = JSON.parse(JSON.stringify(this.selected));
            this.selectedEdit.transferType = this.paymentTypes.filter(
              (x) => x.text == this.selected.transferType
            )[0].value;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editReceiveItem() {
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/editReceive",
          {
            financialId: this.financialId,
            date: this.selectedEdit.date + this.selectedEdit.time,
            amount: this.selectedEdit.amount,
            description: this.selectedEdit.description,
            transactionFee:
              this.selectedEdit.transferType == "card" ||
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.transactionFee
                : "",
            transferType: this.selectedEdit.transferType,
            chequeAmount:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeAmount
                : "",
            chequeNumber:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeNumber
                : "",
            chequeBankName:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeBankName
                : "",
            chequeDueDate:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeDueDate
                : "",
            chequeSayyad:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeSayyad
                : "",
            chequeStatus:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeStatus
                : "",
            chequeDescription:
              this.selectedEdit.transferType == "cheque"
                ? this.selectedEdit.chequeDescription
                : "",
            cardNumberSender:
              this.selectedEdit.transferType == "card"
                ? this.selectedEdit.cardNumberSender
                : "",
            cardNumberReciever:
              this.selectedEdit.transferType == "card"
                ? this.selectedEdit.cardNumberReciever
                : "",
            fileIds: this.selectedEdit.fileIds,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.edited = false;
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.showName = [];
            this.update();
            this.getReceivesList();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.edited = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.edited = false;
        });
    },
    onRowSelected(item) {
      this.financialId = item.id;
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/show",
          {
            financialId: this.financialId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.selected = res.data;
            this.selectedEdit = JSON.parse(JSON.stringify(this.selected));
            this.selectedEdit.transferType = this.paymentTypes.filter(
              (x) => x.text == this.selected.transferType
            )[0].value;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
      this.edited = false;

      this.receiveItemDialog = true;
    },
    myFilterFn(item, Filter) {
      if (item.role == "employee" && item.userName.includes(Filter)) {
        return true;
      } else if (item.role == "patient" && item.patientName.includes(Filter)) {
        return true;
      } else if (
        item.role.includes(Filter) ||
        item.date.includes(Filter) ||
        item.time.includes(Filter) ||
        item.transferType.includes(Filter) ||
        item.description.includes(Filter)
      ) {
        return true;
      } else if ("کارمند".includes(Filter) && item.role == "employee") {
        return true;
      } else if ("بیمار".includes(Filter) && item.role == "patient") {
        return true;
      } else {
        return false;
      }
    },
    submitRefund() {
      this.isRefunding = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/refund",
          {
            financialId: this.refundId,
            transferType: this.selectedRefund.transferType,
            chequeAmount:
              this.selectedRefund.transferType == "cheque"
                ? this.selectedRefund.amount
                : "",
            chequeNumber:
              this.selectedRefund.transferType == "cheque"
                ? this.selectedRefund.chequeNumber
                : "",
            chequeBankName:
              this.selectedRefund.transferType == "cheque"
                ? this.selectedRefund.chequeBankName
                : "",
            chequeDueDate:
              this.selectedRefund.transferType == "cheque"
                ? this.selectedRefund.chequeDueDate
                : "",
            chequeSayyad:
              this.selectedRefund.transferType == "cheque"
                ? this.selectedRefund.chequeSayyad
                : "",
            chequeStatus:
              this.selectedRefund.transferType == "cheque"
                ? this.selectedRefund.chequeStatus
                : "",
            chequeDescription:
              this.selectedRefund.transferType == "cheque"
                ? this.selectedRefund.chequeDescription
                : "",
            cardNumberSender:
              this.selectedRefund.transferType == "card"
                ? this.selectedRefund.cardNumberSender
                : "",
            cardNumberReciever:
              this.selectedRefund.transferType == "card"
                ? this.selectedRefund.cardNumberReciever
                : "",
            fileIds: this.selectedRefund.fileIds,
            description: this.refundDescription,
            transactionFee: this.selectedRefund.transactionFee,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.isRefunding = false;
            this.refundDialog = false;
            this.myFile2 = new Object({
              uploadPercentage: 0,
            });
            this.showName2 = [];
            this.getReceivesList();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.isRefunding = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    refundDisabled() {
      if (this.selectedRefund.transferType == "card") {
        if (
          this.selectedRefund.cardNumberSender &&
          this.selectedRefund.cardNumberReciever &&
          this.selectedRefund.transactionFee
        ) {
          return true;
        } else return false;
      } else if (this.selectedRefund.transferType == "cheque") {
        if (
          this.selectedRefund.chequeBankName &&
          this.selectedRefund.chequeNumber &&
          this.selectedRefund.chequeDueDate &&
          this.selectedRefund.chequeDescription &&
          this.selectedRefund.chequeStatus &&
          this.selectedRefund.chequeAmount &&
          this.selectedRefund.transactionFee
        ) {
          return true;
        } else return false;
      } else return true;
    },
    refundUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile2.showProgress = true;
      this.myFile2.variant = "info";
      this.myFile2.uploadPercentage = 0;
      this.myFile2.isUploaded = false;
      this.myFile2.uploadButDisable = true;
      var formData = new FormData();
      this.showName2.push(file.name);
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/financial/document/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile2.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.myFile2.isUploaded = true;
            this.myFile2.uploadPercentage = 100;
            this.myFile2.striped = false;
            this.myFile2.variant = "success";
            this.myFile2.uploadButDisable = false;
            this.selectedRefund.files.push(d.data);
            let filesLength = this.selectedRefund.files.length;
            let namesLength = this.showName2.length;
            this.selectedRefund.files[filesLength - 1].name =
              this.showName2[namesLength - 1];
            this.selectedRefund.fileIds.push(d.data.id);
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    openModal(item) {
      this.refundId = item.id;
      this.$http
        .post(
          this.baseUrl + "/clinic/financial/document/show",
          {
            financialId: this.refundId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.selectedRefund = res.data;
            this.selectedRefund.transferType = this.paymentTypes.filter(
              (x) => x.text == this.selectedRefund.transferType
            )[0].value;
            this.refundDialog = true;
            this.refundDescription =
              " استرداد دریافت به تاریخ " +
              this.selectedRefund.date +
              " و ساعت " +
              this.selectedRefund.time;
            if (this.selectedRefund.transferType == "cheque") {
              this.selectedRefund.chequeStatus = this.chequeStatuses.filter(
                (x) => x.text == this.selectedRefund.chequeStatus
              )[0].value;
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  computed: {
    ...mapGetters(["paymentTypes"]),
    computedAddBtn() {
      if (
        this.receiveDetail.userId &&
        this.receiveDetail.amount &&
        this.receiveDetail.newDate &&
        this.receiveDetail.newTime &&
        this.receiveDetail.transferType &&
        this.receiveDetail.description
      ) {
        if (this.receiveDetail.transferType == "card") {
          if (
            this.receiveDetail.cardNumberSender &&
            this.receiveDetail.cardNumberReciever &&
            this.receiveDetail.transactionFee
          ) {
            return false;
          } else return true;
        } else if (this.receiveDetail.transferType == "cheque") {
          if (
            this.receiveDetail.chequeBankName &&
            this.receiveDetail.chequeAmount &&
            this.receiveDetail.chequeNumber &&
            this.receiveDetail.chequeNewDate &&
            this.receiveDetail.chequeStatus &&
            this.receiveDetail.transactionFee
          ) {
            return false;
          } else return true;
        } else return false;
      } else return true;
    },
    computedEditBtn() {
      if (
        this.selectedEdit.amount &&
        this.selectedEdit.date &&
        this.selectedEdit.time &&
        this.selectedEdit.transferType &&
        this.selectedEdit.description
      ) {
        if (this.selectedEdit.transferType == "card") {
          if (
            this.selectedEdit.cardNumberSender &&
            this.selectedEdit.cardNumberReciever &&
            this.selectedEdit.transactionFee
          ) {
            return false;
          } else return true;
        } else if (this.selectedEdit.transferType == "cheque") {
          if (
            this.selectedEdit.chequeBankName &&
            this.selectedEdit.chequeAmount &&
            this.selectedEdit.chequeNumber &&
            this.selectedEdit.chequeDueDate &&
            this.selectedEdit.chequeStatus &&
            this.selectedEdit.transactionFee
          ) {
            return false;
          } else return true;
        } else return false;
      } else return true;
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.vLoading = true;
    this.getReceivesList();
  },
};
</script>
<style lang="scss">
.receives {
  .trClass {
    cursor: pointer;
    &:hover {
      background-color: rgb(236, 236, 236);
    }
    &.table-active {
      background-color: #fff !important;
    }
  }
}
.modal-card {
  .cardInfo-box {
    border-right: 2px solid #ccc;
    width: fit-content !important;
  }

  .v-file-input .v-file-input__text.v-file-input__text--chips {
    display: none !important;
  }
  .files-box {
    border: 1px solid rgb(172, 172, 172);
    text-align: right;
    font-size: 14px;
    margin-top: 35px;
    padding: 10px;
    border-radius: 7px;
    position: relative;
    ul {
      li {
        text-decoration: none;
      }
    }
    .files-title {
      position: absolute;
      top: -9px;
      right: 20px;
      background-color: #fff;
      font-size: 14px;
      padding: 0 6px;
    }
  }
}
.files-box {
  border: 1px solid rgb(172, 172, 172);
  text-align: right;
  font-size: 14px;
  margin-top: 35px;
  padding: 10px;
  border-radius: 7px;
  position: relative;
  ul {
    li {
      text-decoration: none;
    }
  }
  .files-title {
    position: absolute;
    top: -9px;
    right: 20px;
    background-color: #fff;
    font-size: 14px;
    padding: 0 6px;
  }
}
.v-file-input .v-file-input__text.v-file-input__text--chips {
  display: none !important;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 82%;
}
</style>
